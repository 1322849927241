import React, {useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {LoaderMedium} from './../../components/loader';
import FormStep from "../../components/form/step";
import {SetMetaData} from './../../helper/page-title';
import {get_api_call, error_api_call} from './../../actions/action-creators/common';
import { alertResponseMessage } from "../../actions/action-creators/response";
//Form
import StepOneForm from "./step-one";
import StepTwoForm from "./step-two";
import StepThreeForm from "./step-three";
import StepFourForm from "./step-four";
import StepFiveForm from "./step-five";
import StepSixForm from "./step-six";
import './../../assets/css/pages/team.css';
import './../../assets/css/pages/social.css';

function SocialForm(){
    SetMetaData("SS – Social Start Up", "", "");
    const winLocation = useLocation();
    const [formStepData, setFormStep] = useState({currentFormStep: 0});
    const [socialData, setSocialData] = useState({ref_id: 0, stage_id: 0, url: 'demo'});
    const [pageLoading, setPageLoader] = useState(false);
    useEffect(() => {
        console.log('sasasasasasas 55555')
        const windowPath = window.location.pathname.split('/')[2];
        if(windowPath === 'edit'){
            const fetchSsStage = async(ref_id) => {
                try{
                    const statgeApiData = await get_api_call(`ss/stage/${ref_id}`, {});
                    if(statgeApiData.status === 200){
                        setSocialData({...socialData, ref_id, stage_id: statgeApiData.data.stage_id, url: statgeApiData.data.url});
                        setFormStep({...formStepData, currentFormStep: statgeApiData.data.stage_id+1});
                    }else if(statgeApiData.status === 404
                        || statgeApiData.status === 401
                    ){
                        alertResponseMessage({alert_type: 2, message: statgeApiData.message});
                    }else{
                        alertResponseMessage({alert_type: 2, message: "Error Loading Social Start-up Details! Check back after some times"});
                    }
                }catch(e){
                    console.log(e);
                    alertResponseMessage({alert_type: 2, message: "Error Loading Social Start-up Details! Check back after some times"});
                    error_api_call({error: `social/form|fetchSsStage|${e}`});
                }
            }
            //Call API to check which step
            const addressBarQuery = new URLSearchParams(winLocation.search);
            const refId = addressBarQuery.get('ref_id');
            if(refId){
                //Check Step by ref id
                fetchSsStage(refId);
                setPageLoader(false);
            }else{
                alertResponseMessage({alert_type: 2, message: "Social Start-up Not Found!"});
            }
        }else{
            setFormStep({...formStepData, currentFormStep: 1});
        }
    }, []);
    //Update Step Details after form Submission
    const updateFormStep = (data) =>{
        try{
            if(data.next_step === 2
                && socialData.ref_id === 0
            ){
                setSocialData({...socialData, ref_id: data.social_id, stage_id: data.stage_id, url: data.url});
            }else{
                setSocialData({...socialData, stage_id: data.stage_id});
            }
            setFormStep({...formStepData, currentFormStep: data.next_step});
            const section = document.querySelector( '#step-section' );
            section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
        }catch(e){

        }
    }
    //Navigate through form
    const navigateForm = (event, step) => {
        event.preventDefault();
        try{
            setFormStep({...formStepData, currentFormStep: step})
        }catch(e){

        }
    }
    return (
        <main id="main">
            <section className="team-sec pt-0 portfolio-edit">
                <div className="container-fluid container-lg">
                    <div className="section-header section-header-1">
                        <h1 className="title">SS-Social Start up</h1>
                    </div>
                    
                    <div className="row justify-content-center team-detail m-1">
                    {pageLoading || formStepData.currentFormStep === 0
                    ? 
                        <div className="text-center mt-5 mb-5">
                            <LoaderMedium size={45} />
                        </div>
                    :
                        <>
                        <div id="step-section">
                        <FormStep
                            formSteps = {[{title: "TEAM FORMATION"},
                                        {title: "IDENTIFICATION"},
                                        {title: "PLANNING"},
                                        {title: "INNOVATING"},
                                        {title: "ROLL OUT"},
                                        {title: "REPORTING"}]}
                            currentFormStep = {formStepData.currentFormStep}
                            completed_step = {socialData.stage_id}
                            navigateForm = {(event, step) => navigateForm(event, step)}
                        />
                        </div>
                        {formStepData.currentFormStep === 1
                        ?
                            <StepOneForm
                                data={socialData}
                                updateFormStep={(data) => updateFormStep(data)}
                            />
                        :
                            formStepData.currentFormStep === 2
                            ?
                                <StepTwoForm
                                    data={socialData}
                                    updateFormStep={(data) => updateFormStep(data)}
                                />
                            :
                                formStepData.currentFormStep === 3
                                ?
                                    <StepThreeForm
                                        data={socialData}
                                        updateFormStep={(data) => updateFormStep(data)}
                                    />
                                :
                                    formStepData.currentFormStep === 4
                                    ?
                                        <StepFourForm
                                            data={socialData}
                                            updateFormStep={(data) => updateFormStep(data)}
                                        />
                                    :
                                        formStepData.currentFormStep === 5
                                        ?
                                            <StepFiveForm
                                                data={socialData}
                                                updateFormStep={(data) => updateFormStep(data)}
                                            />
                                        :
                                            formStepData.currentFormStep === 6
                                        ?
                                            <StepSixForm
                                                data={socialData}
                                                updateFormStep={(data) => updateFormStep(data)}
                                            />
                                        :
                                            <></>
                        }
                        
                        </>
                    }
                    </div>
                </div>
            </section>
        </main>
        
    )    
}

export default SocialForm;