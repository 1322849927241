/* eslint-disable-next-line */
import React, {useEffect, lazy, Suspense} from "react";
import { useDispatch} from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import {update_device_data, auth_token_update} from './actions/action-creators/state-update';
import './assets/css/bootstrap5/bootstrap.min.css';
//Css
import './assets/css/main/style-main.css?v=1';
import './assets/css/style.css?v=3.4';
// import logo from './assets/images/main/logo/ifspd_logo_m.png';
// import './assets/css/main/css.css';
// import './assets/css/main/styles.css';
// import './assets/css/main/style.min.css';
// import './assets/css/main/front-flex.min.css';
// import './assets/css/main/unsemantic-grid.min.css';
// import './assets/css/main/mobile.min.css';
// import './assets/css/main/font-icons.min.css';

// import './assets/css/main/jquery.fancybox.min.css';
// import './assets/css/main/frontend.css';
// import './assets/css/main/all.css';
// import './assets/css/main/v4-shims.css';
// import './assets/css/main/style1.min.css';
// import './assets/css/main/style-mobile.min.css';
// import './assets/css/main/sticky.min.css';
// import './assets/css/main/offside.min.css';
// import './assets/css/main/icons.min.css';
// import './assets/css/main/navigation-branding.min.css';
// import './assets/css/main/main.css';


// import './assets/css/style.css';
// import './assets/css/font-awesome.min.css';
// import './assets/css/LineIcons.4.0.css';
// import Header from './components/header';
// import {Footer} from './components/footer';
import NotFound from './screens/404';
import {LoaderMedium} from './components/loader';
// const Home = lazy(() => import('./screens/home'));
import Home from './screens/home';
// About
import History from './screens/about/history';
import Founder from './screens/about/founders';
import Principle from './screens/about/principle';
// Organization
import Committee from './screens/organization/committee';
import LegalEntity from './screens/organization/legal-entity';
import Team from './screens/organization/team';
import OurPartner from './screens/organization/partner';
// Fellow
// import FellowProfile from './screens/fellow/profile';
// import FellowDetail from './screens/fellow/detail';
import FellowProfile from './screens/fellow/profile-new';
import FellowDetail from './screens/fellow/profile-detail-new';
// import Presidential from "./screens/fellow/presidential";
import FellowAbout from "./screens/fellow/about";
import FellowCampg from "./screens/fellow/campaign";
import Sustainability from "./screens/fellow/sustainability";
import FellowProfileForm from "./screens/fellow/profile-form";
//Login
import Login from './screens/auth/login';
import Logout from "./screens/auth/logout";
//Private-Portfolio
// import PortfolioForm from "./screens/portfolio/form";
import PortfolioDetail from './screens/portfolio/detail';
import PortfolioEdit from './screens/portfolio/edit';
import PortfolioMain from './screens/portfolio/landing';
// Social Start-Up
import SocialForm from './screens/social/form';
import SocialFormMy from './screens/social/list';
import SocialDetails from './screens/social/details';
import SocialMaster from './screens/social/landing';
// Certification
import LeaderCertification from "./screens/fellow/certification/young-leader";
// Gallery
import Gallery from './screens/gallery/gallery';
import Contact from './screens/contact';
// Static
import PrivacyPolicy from './screens/static/privacy-policy';
import TermsCondition from "./screens/static/terms-conditions";
// Events
import Events from './screens/event/list';
import Event1 from './screens/event/1';
import Event2 from './screens/event/2';
import Event3 from './screens/event/3';
import Event4 from './screens/event/4';
import Event5 from './screens/event/5';
import Event6 from './screens/event/6';
import Event7 from './screens/event/7';
import Event8 from './screens/event/8';
import Event9 from './screens/event/9';
import Event10 from './screens/event/10';

import WrapMaster from './components/wrapper/main';
// const loading = (
//     <div className="preloader flex-column justify-content-center align-items-center">
//       <img className="animation__shake" src={logo} alt="IFSPD" height="200" width="200" />
//     </div>
// )
//Public
// const Home = lazy(() => import('./screens/home'));
// const WrapMaster = lazy(() => import('./components/wrapper/main'));
import PrivateRoute from "./routes/private";
import AuthRoute from "./routes/auth";

function App() {
    const dispatch = useDispatch();
    // const getDeviceId = () => {
    //     return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    //       var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
    //       return v.toString(16);
    //     });
    // }
    useEffect(() => {
        //Check if Device Id Set, if not Set New
        if(!localStorage.getItem("device_id")){
            const deviceID = uuidv4();
            //Dispatch and check if it is available in database
            update_device_data({device_id: deviceID});
            // console.log(deviceID, 'deviceID')
        }
        // else{
        //     console.log('nnnnnnnnnnnn')
        // }
        dispatch(auth_token_update());
    }, []);// eslint-disable-line react-hooks/exhaustive-deps
      
    return (
        <>
        {/* <Suspense fallback={loading}> */}
        <div id="bodyOverlay">
            <LoaderMedium width={65} />
        </div>
        <ReactNotification />
        {/* <Header/> */}
        {/* <Navbar /> */}
        {/* <div className="wrapper" style={{minHeight: '70vh'}}> */}
            {/* <div className="container"> */}
            
            <Router>
                <Routes>
                    <Route path='*' element={<NotFound/>} />
                    {/* Auth: Guest Route */}
                    <Route element={<AuthRoute/>}>
                        <Route exact path='/login' element={<WrapMaster showLogo={false}><Login/></WrapMaster>}/>
                        {/* <Route exact path='/reset-password' element={<ResetPassword/>}/> */}
                    </Route>
                    {/* Routh For Authenticated User */}
                    <Route element={<PrivateRoute/>}>
                        {/* <Route exact path='/verification' element={<Verification/>}/> */}
                        {/* <Route exact path='/checkout/address' element={<MyAddress/>}/> */}
                        {/* <Route exact path="/port" element={<WrapMaster><PortfolioForm/></WrapMaster>}/> */}
                        <Route exact path="/port" element={<PortfolioMain/>}/>
                        <Route exact path="/port/:profile_id" element={<WrapMaster><PortfolioDetail/></WrapMaster>}/>
                        <Route exact path="/port/edit" element={<WrapMaster><PortfolioEdit/></WrapMaster>}/>
                        {/* Social Start-Up */}
                        <Route exact path="/social-start-up" element={<WrapMaster><SocialMaster/></WrapMaster>}/>
                        <Route exact path="/social-start-up/create" element={<WrapMaster><SocialForm/></WrapMaster>}/>
                        <Route exact path="/social-start-up/edit" element={<WrapMaster><SocialForm/></WrapMaster>}/>
                        <Route exact path="/social-start-up/my" element={<WrapMaster showLogo={false}><SocialFormMy/></WrapMaster>}/>
                        <Route exact path="/social-start-up/details/:startup_id/:startup_url" element={<WrapMaster showLogo={false}><SocialDetails/></WrapMaster>}/>
                    </Route>
                    <Route exact path='/logout' element={<WrapMaster showLogo={false}><Logout/></WrapMaster>}/>
                    {/* Public Route */}
                    {/* <Route path='/' element={<PublicRoute/>} /> */}
                    {/* <Route path='/'>
                        <PublicRoute />
                    </Route> */}
                    {/* <Route element={<NotFound/>} /> */}
                    {/* Public Route */}
                    <Route exact path="/" element={<WrapMaster><Home/></WrapMaster>}/>
                    <Route exact path="/home" element={<WrapMaster><Home/></WrapMaster>}/>
                    {/* About */}
                    <Route exact path="/history" element={<WrapMaster><History/></WrapMaster>}/>
                    <Route exact path="/founders" element={<WrapMaster><Founder/></WrapMaster>}/>
                    <Route exact path="/principles-and-objectives" element={<WrapMaster><Principle/></WrapMaster>}/>
                    {/* Fellow */}
                    <Route exact path="/fellow-profiles" element={<WrapMaster><FellowProfile/></WrapMaster>}/>
                    <Route exact path="/fellow-profile/:profile_id" element={<WrapMaster><FellowDetail/></WrapMaster>}/>
                    <Route exact path="/fellow-profile-form" element={<WrapMaster><FellowProfileForm/></WrapMaster>}/>
                    {/* Apply */}
                    <Route path='/apply' element={<RedirectExternal url="https://admin.gosurvey.in/survey/parent" />} />
                    {/* Portfolio Form */}
                    {/* <Route exact path="/portfolio-form" element={<WrapMaster><PortfolioForm/></WrapMaster>}/> */}
                    {/* <Route exact path="/fp" element={<WrapMaster showLogo={false}><Presidential/></WrapMaster>}/> */}
                    <Route exact path="/fp" element={<WrapMaster showLogo={false} ><FellowCampg showHeader={true} /></WrapMaster>}/>
                    <Route exact path="/global-sustainability-certification" element={<WrapMaster showLogo={false} ><Sustainability showHeader={true} /></WrapMaster>}/>
                    <Route exact path="/young-leader-certification" element={<WrapMaster showLogo={false} showPartner={false}><LeaderCertification showHeader={true}/></WrapMaster>}/>
                    <Route exact path="/fellowship" element={<WrapMaster showLogo={false}><FellowAbout/></WrapMaster>}/>
                    <Route exact path="/campaign" element={<WrapMaster showLogo={false} showHeader={false} showFooter={false}><FellowCampg/></WrapMaster>}/>
                    {/* <Route exact path="/fellowship-campaign" element={<WrapMaster showLogo={false} showHeader={false} showFooter={false}><FellowCampg/></WrapMaster>}/> */}
                    {/* Organization */}
                    <Route exact path="/presidential-committee" element={<WrapMaster><Committee/></WrapMaster>}/>
                    <Route exact path="/legal-entity" element={<WrapMaster><LegalEntity/></WrapMaster>}/>
                    <Route exact path="/our-team" element={<WrapMaster><Team/></WrapMaster>}/>
                    <Route exact path="/our-partners" element={<WrapMaster><OurPartner/></WrapMaster>}/>
                    {/* Gallery */}
                    <Route exact path="/gallery" element={<WrapMaster><Gallery/></WrapMaster>}/>
                    <Route exact path="/contact" element={<WrapMaster><Contact/></WrapMaster>}/>
                    {/* Static */}
                    <Route exact path="/privacy-policy" element={<WrapMaster><PrivacyPolicy/></WrapMaster>}/>
                    <Route exact path="/terms-and-conditions" element={<WrapMaster><TermsCondition/></WrapMaster>}/>

                    {/* Events */}
                    <Route exact path="/board-meetings" element={<WrapMaster><Events/></WrapMaster>}/>
                    <Route exact path="/board-meetings/resolutions-of-18th-board-of-directors-meeting" element={<WrapMaster><Event1/></WrapMaster>}/>
                    <Route exact path="/board-meetings/18th-ifspd-board-of-directors-meeting-held-in-belgrade-serbia" element={<WrapMaster><Event2/></WrapMaster>}/>
                    <Route exact path="/board-meetings/ifspd-17th-extended-board-of-directors-and-general-assembly-meetings" element={<WrapMaster><Event3/></WrapMaster>}/>
                    <Route exact path="/board-meetings/resolution-of-16th-general-assembly-meeting" element={<WrapMaster><Event4/></WrapMaster>}/>
                    <Route exact path="/board-meetings/resolution-of-16th-board-of-directors-meeting" element={<WrapMaster><Event5/></WrapMaster>}/>
                    <Route exact path="/board-meetings/16th-board-of-directors-and-general-assembly-meeting" element={<WrapMaster><Event6/></WrapMaster>}/>
                    <Route exact path="/board-meetings/the-anniversary-general-assembly-and-12th-board-meeting" element={<WrapMaster><Event7/></WrapMaster>}/>
                    <Route exact path="/board-meetings/the-15th-board-of-directors-and-general-assembly-meeting" element={<WrapMaster><Event8/></WrapMaster>}/>
                    <Route exact path="/board-meetings/the-15th-meeting-of-the-board-of-directors-and-general-assembly-of-the-bscsif" element={<WrapMaster><Event9/></WrapMaster>}/>
                    <Route exact path="/board-meetings/the-14th-general-assembly-and-board-meeting-of-the-bscsif-baku-azerbaijan" element={<WrapMaster><Event10/></WrapMaster>}/>   
                </Routes>
                {/* <PublicRoute /> */}
            </Router>
            
            {/* </div> */}
        {/* </div> */}
        {/* <Footer /> */}
        {/* </Suspense> */}
        </>
    );
}
function RedirectExternal(props) {
    window.location.href = props.url;
    return null;
}
export default App;