import React from 'react';
import picIcon from "./../../assets/images/user.png";

function SocialListComp(props) {
    return (
      <> 
        <div className="team-card">
            <div className="team-detail topic-detail mb-5">   
                <h2>
                <a href={`/social-start-up/details/1/${props.data.url}`} target="_blank" rel="noopener noreferrer">
                    <strong className="fs-6">{props.data.title} {props.data.last_name || ''}</strong>
                </a>
                {props.isMy && (
                    <div className="position justify-content-between d-flex icon">
                        <span><a href={`/social-start-up/edit?ref_id=${props.data.id}`} target="_blank" rel="noopener noreferrer"><i className="fa fa-edit"></i> Edit</a></span>
                        <span><a href={`/social-start-up/details/1/${props.data.url}`} target="_blank" rel="noopener noreferrer"><i className="fa fa-eye"></i> View</a></span>
                    </div>
                )}
                {/* <span className="position">{props.data.position}, {props.data.designation}</span> */}
                {/* <span className="position">{props.data.title}</span> */}
                {/* <div className="position justify-content-between d-flex icon">
                    <span><a href={`/social-start-up/create?ref_id=${props.data.id}`} target="_blank" rel="noopener noreferrer"><i className="fa fa-edit"></i> Edit</a></span>
                    <span><a href={`/social-start-up/details/1/${props.data.url}`} target="_blank" rel="noopener noreferrer"><i className="fa fa-eye"></i> View</a></span>
                </div> */}
                
                {/* <!-- <ul className="d-flex">
                <li><a href={team_d.facebook} className="facebook"><i className="fa-brands fa-facebook-f"></i></a></li>
                <li><a href={team_d.twitter} className="twitter"><i className="fa-brands fa-twitter"></i></a></li>
                <li><a href={team_d.instagram} className="instagram"><i className="fa-brands fa-instagram"></i></a></li>
                <li><a href={team_d.behance} className="linkedin"><i className="fa-brands fa-linkedin"></i></a></li>
                </ul> --> */}
                </h2>
                <div className="img-wrapper">
                <a href={`/social-start-up/details/1/${props.data.url}`} target="_blank" rel="noopener noreferrer">
                    <div className="img-wrap card">
                        {/* <img src={props.data.logo} alt={`${props.data.first_name} ${props.data.last_name || ''}`}/> */}
                        <img src={picIcon} alt={`${props.data.first_name} ${props.data.last_name || ''}`}/>
                    </div>
                </a>
                </div>
            </div>
        </div>
      </>
    );
}
  
export default SocialListComp;