import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import '../../assets/css/scss/progress-bar.scss';

const FormStep = (props) => {
    // const isMounted = useRef(null);
    const formStepsData = typeof props.formSteps !== "undefined" ? props.formSteps : [];
    const currentFormStep = typeof props.currentFormStep !== "undefined" ? props.currentFormStep : 1;
    const completedFormStep = typeof props.completed_step !== "undefined" ? props.completed_step : 0;
    
    // const submitDisabled = props.submitDisabled;
    
    const getProgressClass = (step_index) => {
        step_index = step_index+1;
        var classname = "disabled";
        if(parseInt(currentFormStep) === step_index){
            if(step_index <= completedFormStep){
                classname = "active active-completed";
            }else{
                classname = "active";
            }
        }else if(
            step_index < parseInt(currentFormStep)
            || step_index <= completedFormStep
        ){
            classname = "complete";
        }
            console.log(step_index, 'ppp', completedFormStep)
        return classname;
    }
    
    return (
        <>
            {formStepsData.length > 0 && (
                <div className="progress-block mb-5">
                    {/* <div> */}
                        {formStepsData.map((step, step_index) => (
                            <div key={`$step${step_index}`} className={`progress-block-step ${getProgressClass(step_index)}`}>
                                <div className="text-center progress-block-stepnum">Step {step_index+1}</div>
                                <div className="progress"><div className="progress-bar"></div></div>
                                {(step_index+1 > currentFormStep 
                                    && step_index+1 > completedFormStep+1)
                                    ?
                                    <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 0, hide: 0 }}
                                        overlay={<Tooltip>Complete Previous Step to Unlock this Step</Tooltip>}
                                    >  
                                        <button
                                            className="btn progress-block-dot"
                                            disabled={true}
                                            // onClick={(event) => event.preventDefault()}
                                        ><i className="fa fa-file-signature"></i></button>
                                    </OverlayTrigger>
                                    :  
                                    <button
                                        className="btn progress-block-dot clickable"
                                        // disabled={step_index+1 > currentFormStep ? true : false}
                                        onClick={(event) => props.navigateForm(event, step_index+1)}
                                    ><i className="fa fa-file-signature"></i></button>
                                }
                                {/* <span className="progress-block-dot"></span> */}
                                <div className="progress-block-info text-center">{step.title}</div>
                            </div>
                        ))}
                    {/* </div> */}
                </div>
            )}
        </>
    );
  }

  export default FormStep;