import React from 'react';

const CharacterCount = (props) => {
    
    return (
        <>
            <p className={`fs-12p mb-1 mt-1 text-end ${props.string.length > props.max ? 'color-red' : ''}`}>
                <b>{props.string.length}</b>/{props.max} characters
            </p>
        </>
    );
  }

export {
    CharacterCount
};