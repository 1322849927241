import React, {useState, useEffect, useRef} from "react";
import { useLocation } from "react-router-dom";
import {SetMetaData} from './../../helper/page-title';
import Select from 'react-select';
import './../../assets/css/pages/team.css';
import {get_api_call, post_api_call, error_api_call} from '../../actions/action-creators/common';
import {alertResponseMessage} from '../../actions/action-creators/response';
import { RSstandardStyle } from "../../config/react-select";
import {toggleBodyLoader} from '../../helper/custom-function';
import {LoaderMedium} from './../../components/loader';
import noFellow from '../../assets/images/oops.png';
import SocialListComp from "../../components/social/list";
const currentDate = new Date();
const currentYear = currentDate.getFullYear();
// const startYear = currentYear - 100;
const selectOptionLabel = {ifspd_program_id: 'program_name', ifspd_program_year: 'year', country_id: 'name'};
const stageData = [{id: 1, stage: 'Stage 1'},
    {id: 2, stage: 'Stage 2'},
    {id: 3, stage: 'Stage 3'},
    {id: 4, stage: 'Stage 4'},
    {id: 5, stage: 'Stage 5'},
    {id: 6, stage: 'Stage 6'}];
function MySocialList(){
    SetMetaData("My Social Start-ups", "", "");
    const location = useLocation();
    const [countryData, setCountryData] = useState([]);
    const [programData, setProgramData] = useState([]);
    const [yearData, setYearData] = useState([]);
    const [filterData, setFilterData] = useState({name: '',
                                                ifspd_program_id: '',
                                                creation_date: '',
                                                ifspd_program_year: '',
                                                country_id: ''});
    const [fellowData, setFellowData] = useState([{image: '', title: 'Title 1', id: 1},
                                                    {image: '', title: 'Title 1', id: 1}
                                                ]);
    // const [fellowTotal, setFellowTotal] = useState(0);
    const [fellowPage, setFellowPage] = useState(0);
    const [callFellowAPI, setCallFellowAPI] = useState(0);
	//Loading
	const [showFellowLoader, setFellowLoader] = useState(false);
	const [fellowLoading, setFellowLoading] = useState(false);
	const [pageLoading, setPageLoading] = useState(false);
	const limit = 15;
	const loadMoreFellow= useRef(null);//Button Click
	const fellowLoader = useRef(null);
    const [domainList, setDomainList] = useState([]);
    const [stateData, setStateData] = useState([]);
    const [schoolList, setSchoolList] = useState([]);
    // Update Filter Input
    const updateInput = (input_type, field, value, select_option='') => {
        try{
            return;
            if(field === 'name'){
                value = value.replace(/[^a-zA-Z. ]/g, '')
                            .replace('  ', ' ');
            }
            const filledFilterData = {...filterData, [field]: value};
            // setFilterData({...filterData, [field]: value});
            setFilterData({...filledFilterData});
            if(field !== 'name'){
                console.log('yes')
                // searchFellow();
                // setFellowPage(1);
                toggleBodyLoader('load');
                if(filledFilterData[field].id === ''){
                    modifyAdBarUrl(field, '');
                }else{
                    modifyAdBarUrl(field, `${filledFilterData[field].id},${filledFilterData[field][select_option]}`);
                }
                setPageLoading(true);
                setFellowPage(fellowPage === 1 ? 0 : 1);
            }
        }catch(e){
            alertResponseMessage({alert_type: 2, message: "Error Loading Fellow Details! Check back after some times"});
            // console.log(e)
            error_api_call({error: `fellow/profile|updateInput|${e}`});
            return false;
        }
    }
    //Search by Filter Data
    const searchFellow = (e) => {
        try{
            e.preventDefault();
            toggleBodyLoader('load');
            modifyAdBarUrl('name', filterData.name);
            setPageLoading(true);
            setFellowPage(fellowPage === 1 ? 0 : 1);
        }catch(e){
            alertResponseMessage({alert_type: 2, message: "Error Loading Fellow Details! Check back after some times"});
            // console.log(e)
            error_api_call({error: `fellow/profile|searchFellow|${e}`});
        }
    }
    //Get Data for Country & Program
    // const fetchSelectList = async(type) => {
    //     try{
	// 		const listApiData = type === 'program'
    //                                 ? await get_api_call('ifspd-program', {})
    //                                 : await get_api_call('countries', {});
	// 		if(listApiData.status === 200){
	// 			type === 'program'
    //                 ? setProgramData(listApiData.data)
    //                 : setCountryData(listApiData.data);
	// 		}
    //     }catch(e){
	// 		error_api_call({error: `fellow|profile|fetchSelectList|${e}`});
    //     }
    // }
    const fetchSelectList = async(type, url) => {
        try{
            const selectApiData = await get_api_call(url, {});
            if(selectApiData.status === 200){
                switch (type) {
                    case 'domain':
                        setDomainList(selectApiData.data);
                        break;
                    case 'state':
                        setStateData(selectApiData.data);
                        break;
                    // case 'school':
                    //     setSchoolList(selectApiData.data);
                    //     break;
                    default: 
                }
            }
        }catch(e){
			error_api_call({error: `social|step-two|fetchSelectList|${e}`});
        }
    }
    //Creating Year Array
    const yearDataList = (type) => {
        const yearItems = [];
        // for (let i = currentYear; i >= startYear; i--) {
        for (let i = currentYear; i >= 2020; i--) {
        //   yearItems.push(<option key={`${type}_${i}`} value={i}>{i}</option>);
            yearItems.push({id: i, year: i});
        }
        setYearData(yearItems);
        // return yearItems;
    };
    //Modify URL
	const modifyAdBarUrl = (key, value) => {
		try{
			// if(typeof value === 'array'){
			// if(value instanceof Array){
				const url = new URL(window.location.href);
				if(!value){//value.length === 0
					url.searchParams.delete(key);
				}else{
					url.searchParams.set(key, value);
				}
				
				window.history.pushState({}, '', url.toString());
			// }
		}catch(e){
			// console.log(e)
			error_api_call({error: `fellow/profile|modifyAdBarUrl|${e}`});
			return false;
        }
	};
	//Load More Data when loader is in focus
	const infiniteCustomScroll = () => {
		if(loadMoreFellow.current){
			loadMoreFellow.current.click();
		}
	}
	//Initial Load & Read More Implementation
	useEffect(() => {
        const options = {
           root: null,
           rootMargin: "20px",
           threshold: 1.0
        };
       // initialize IntersectionObserver
       // and attaching to Load More div
        //For All
		const observerAll = new IntersectionObserver(infiniteCustomScroll, options);
        if (fellowLoader.current) {
            observerAll.observe(fellowLoader.current)
        }
		const clean = fellowLoader.current;
        return () => {
            if (clean) {
                observerAll.disconnect(clean);
            }
        }
    }, [fellowLoader.current]);// eslint-disable-line react-hooks/exhaustive-deps
    //Call Filter Data
	useEffect(() => {
        console.log('urlHandling')
		const urlHandling = () => {
			try{
				//URL Param type Handling
				const urlQuryString = location.search;
				const urlParam = new URLSearchParams(urlQuryString);
				if(urlParam.size > 0){
					// const filterKeys = ['name', 'status', 'year', 'program', 'sector', 'country'];
                    const filterKeys = ['name', 'ifspd_program_id', 'ifspd_program_year', 'country_id'];
					var filterDataNew = {...filterData};
					urlParam.forEach((value, key) => {
						if(filterKeys.indexOf(key) > -1){
                            if(key !== 'name'){
                                var urlParamData = value.split(',');
                                filterDataNew[key] = {id: parseInt(urlParamData[0]), [selectOptionLabel[key]]: urlParamData[1]};
                            }else{
                                filterDataNew[key] = value;
                            }
							console.log(value, key);
						}
					});
                    console.log(filterDataNew, 'filterDataNewfilterDataNewfilterDataNew')
					// setFilterData({...filterDataNew});
                    // setTimeout(() => {
                        setFilterData({...filterDataNew});
                    // }, 13000)
                    
                    console.log(filterData, 'filterDatafilterDatafilterData')
				}
				setCallFellowAPI(1);
				setFellowPage(fellowPage === 1 ? 0 : 1);
			}catch(e){
				alertResponseMessage({alert_type: 2, message: "Error Loading Fellows! Check back after some times"});
				error_api_call({error: `fellow/profile|urlHandling|${e}`});
			}
		}
        // fetchAPIList('category');
		// fetchAPIList('main-category');
        // fetchSelectList('program');
        // fetchSelectList('country');
        fetchSelectList('state', 'states');
        fetchSelectList('domain', 'domains');
        yearDataList();
        urlHandling();
	}, []);// eslint-disable-line react-hooks/exhaustive-deps
    //Call Fellow Filter API
	useEffect(() => {
		//Get Filtered Fellow
		const fetchData = async() => {
			try{
				//APi Call
				if(!fellowLoading){
					setFellowLoading(true);
					const fellowCurrentPage = fellowPage < 2 ? 1 : fellowPage;
					if(fellowCurrentPage === 1){
						setFellowData([]);
					}
					const newFellowList = await post_api_call('fellows', 
																{limit,
																page: fellowCurrentPage,
																filter: {name: filterData.name,
                                                                        country_id: filterData.country_id !== '' ? filterData.country_id.id : '',
                                                                        ifspd_program_id: filterData.ifspd_program_id !== '' ? filterData.ifspd_program_id.id : '',
                                                                        ifspd_program_year: filterData.ifspd_program_year !== '' ? filterData.ifspd_program_year.id : ''},
																// keyword: queryKeyword,
															});
					if(newFellowList.status === 200){
						const newFellowData = fellowCurrentPage < 2
												? newFellowList.data 
												: fellowData.concat(newFellowList.data);
						setFellowData(newFellowData);
						if(fellowCurrentPage === 1){
							// setFellowTotal(newFellowList.total);
							setPageLoading(false);
						}
					}
					setFellowLoading(false);
					toggleLoader(fellowCurrentPage, newFellowList.total);
					if(fellowCurrentPage === 1){
						toggleBodyLoader('remove');
					}
				}
			}catch(e){
				alertResponseMessage({alert_type: 2, message: "Error Loading Fellow Details! Check back after some times"});
				// console.log(e);
				setFellowLoading(false);
				error_api_call({error: `fellow/profile|fetchData|${e}`});
			}
		}
		if(callFellowAPI === 1){
			// fetchData();
		}
	}, [fellowPage]);// eslint-disable-line react-hooks/exhaustive-deps
	//Load More Fellow
	const loadMore = () => {
		setFellowPage(fellowPage < 2 ? 2 : fellowPage+1);
	}
	//Show-Hide Item Loader
	const toggleLoader = (current_page, total_page) => {
        try{
            var response = {page_count: 0, is_show: false};
			const totalPage = total_page <= limit
							? 1
							: total_page % limit === 0 
							? total_page/limit 
							: parseInt((total_page/limit))+1;
							// console.log(current_page, 'current_page')
							// console.log(totalPage, 'totalPage')
			if(current_page < totalPage){
				response.is_show = true;
				setFellowLoader(true);
			}else{
				setFellowLoader(false);
			}
			response.page_count = totalPage;
            return response;
        }catch(e){
            return response;
        }
    }
    return (
        <main id="main">
        <section className="team-sec pt-0 topic-sec mt-60">
          <div className="container-fluid container-lg pe-lg-5 ps-lg-5">
              <div className="section-header section-header-1">
                  <h1 className="title pt-4">My Social Start-up</h1>
              </div>
              <div className="mb-3 text-end">
                <button className="btn btn-primary bg-color-blue btn-initiate"><a href="/social-start-up/create" target="_blank" rel="noopener noreferrer">Initiate a Social Start-up</a></button>
              </div>
              <div className="mb-5 p-3" style={{backgroundColor: '#f8f9fa', borderRadius: 5,position: 'relative', zIndex: 99}}>
              {/* ms-3 */}
                {/* <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                        <div className="input-group mb-3">
                            <input className="form-control" placeholder="Search by Name"/>
                            <div className="input-group-append">
                                <span className="input-group-text" style={{height: 38}}><i className="fa fa-search"></i></span>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-4 col-sm-12">
                        <div className="input-group">
                            <input className="form-control" placeholder="Search by Social Start-up Name" value={filterData.name} onChange={(e) => updateInput('input', 'name', e.target.value)}/>
                            <div className="input-group-append">
                                <button className="input-group-text" style={{height: 38}} onClick={(e) => searchFellow(e)}><i className="fa fa-search"></i></button>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12 ps-1 pe-1">
                        <Select
                             options={[{id: '', domain: 'ALL'}].concat(domainList)} 
                             // defaultValue={filterData.ifspd_program_id}
                             // value={filterData.domain_id}
                             placeholder={"Domain"}
                             onChange={(value) => updateInput('select-react', 'ifspd_program_id', value, 'program_name')}
                             getOptionLabel ={(option) => option.domain}
                             getOptionValue ={(option) => option.id}
                             menuPlacement="auto"
                             menuPosition={'fixed'}
                             styles={RSstandardStyle}
                        />
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12 ps-1 pe-1">
                        <Select
                            // options={[{id: '', program_name: 'ALL'}].concat(programData)}
                            options={[{id: '', stage: 'ALL'}].concat(stageData)} 
                            // defaultValue={filterData.ifspd_program_id}
                            value={filterData.stage_id}
                            placeholder={"Stage"}
                            onChange={(value) => updateInput('select-react', 'ifspd_program_id', value, 'program_name')}
                            getOptionLabel ={(option) => option.stage}
                            getOptionValue ={(option) => option.id}
                            menuPlacement="auto"
                            menuPosition={'fixed'}
                            styles={RSstandardStyle}
                        />
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12 ps-1 pe-1">
                        {/* <div className="w-110p"> */}
                        <div className="input-group">
                        <input
                            className="form-control"
                            type="text"
                            onFocus={(e) => (filterData.creation_date ? '' : e.target.type = "date")}
                            onBlur={(e) => (filterData.creation_date ? '' : e.target.type = "text")}
                            name="creation_date"
                            autoComplete="off"
                            value={filterData.creation_date}
                            onChange={(e) => updateInput('input', 'creation_date', e.target.value)}
                            placeholder="Creation Date"
                        />
                        </div>
                        {/* </div> */}
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12">
                        {/* <div className="w-200p"> */}
                            <Select
                                options={[{id: '', name: 'ALL'}].concat(stateData)}
                                // value={filterData.country_id}
                                defaultValue={filterData.country_id}
                                placeholder={"State"}
                                onChange={(value) => updateInput('select-react', 'country_id', value, 'name')}
                                getOptionLabel ={(option) => option.state}
                                getOptionValue ={(option) => option.id}
                                styles={RSstandardStyle}
                                menuPlacement="auto"
                                menuPosition={'fixed'}
                            />
                        {/* </div> */}
                    </div>
                    {/* <div className="col-lg-3 col-md-3 col-sm-12">
                    <button className="search-btn" onClick={(event) => searchFellow(event)}>Search</button>
                    </div> */}
                    <span className="text-end"><button className="heading-subtext btn p-0"><i className="fa fa-close"></i> Clear Filter</button></span>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className={`col-md-12 col-12 col-sm-12`}>
					{pageLoading 
					?
						<div className="text-center mt-5">
							<LoaderMedium size={65} />
						</div>
					:
						fellowData.length > 0 ?
							<>
                            <div className="row">
								{fellowData.map((fellow, fellow_indx) => (
                                     <div className="col-md-2 col-12 col-sm-6" key={`fellow-data${fellow_indx}`}>
                                        <SocialListComp
                                            data={fellow}
                                            isMy={true}
                                        />
                                        {/* <div className="team-card">
                                            <div className="team-detail topic-detail">   
                                                <h2>
                                                <a href={`/fellow-profile/${fellow.url}`} target="_blank" rel="noopener noreferrer">
                                                    <strong className="fs-6">{fellow.title} {fellow.last_name || ''}</strong>
                                                </a>
                                                <div className="position justify-content-between d-flex icon">
                                                    <span><a href={`/social-start-up/create?ref_id=${fellow.id}`} target="_blank" rel="noopener noreferrer"><i className="fa fa-edit"></i> Edit</a></span>
                                                    <span><a href={`/social-start-up/details/${fellow.url}`} target="_blank" rel="noopener noreferrer"><i className="fa fa-eye"></i> View</a></span>
                                                </div>
                                                
                                                </h2>
                                                <div className="img-wrapper">
                                                <a href={`/fellow-profile/${fellow.url}`} target="_blank" rel="noopener noreferrer">
                                                    <div className="img-wrap card"><img src={fellow.logo} alt={`${fellow.first_name} ${fellow.last_name || ''}`}/></div>
                                                </a>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                ))}
                            </div>
								{showFellowLoader && 
									<div className="text-center">
										<div ref={fellowLoader}>
											<LoaderMedium />
										</div>
										<button className="btn btn-primary" ref={loadMoreFellow} onClick={() => {loadMore()}}>Load More</button>
									</div>
								}
							</>
						: 
						<div className="row">
							<div className="no-fellow mt-4">
								<img src={noFellow} alt="No Matching Fellow Found" />
								<p className="pt-5 head">Sorry, no matching Fellow found!</p>
								<p className="sub-title ml-2 mr-2">Please try with different filter</p>
							</div>
						</div>
					}
				</div>
              </div>
          </div>
        </section>
      </main>
    )    
}

export default MySocialList;