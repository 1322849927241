import React from 'react';

function HorziLine() {

    return (
        <div id="pg-w5f31c29a23cab-0"  className="panel-grid panel-has-style" >
            <div className="siteorigin-panels-stretch panel-row-style panel-row-style-for-w5f31c29a23cab-0" data-stretch-type="full" >
                <div id="pgc-w5f31c29a23cab-0-0"  className="panel-grid-cell" >
                    <div id="panel-w5f31c29a23cab-0-0-0" className="so-panel widget widget_sow-editor panel-first-child panel-last-child" data-index="0" >
                        <div className="panel-widget-style panel-widget-style-for-w5f31c29a23cab-0-0-0" >
                        <div className="so-widget-sow-editor so-widget-sow-editor-base">
                            <div className="siteorigin-widget-tinymce textwidget">
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HorziLine;